






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import shopStatuses from "@/constants/shopStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";

export default Vue.extend({
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý Shop",
          urlEnabled: true,
          filters: {
            filters: {
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(shopStatuses),
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
            },
            shopUsername: {
              text: "Shop Username",
              sortable: true,
            },
            shopName: {
              text: "Shop Name",
              sortable: true,
            },
            shopId: {
              text: "Shop ID",
              sortable: true,
            },
            status: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return shopStatuses[value || ""];
                },
                attrs(value) {
                  const attrs: any = {};
                  if (value === "locked") {
                    attrs.color = "red";
                  }
                  return attrs;
                },
              },
            },
            email: {
              text: "Email",
              sortable: true,
            },
            url: {
              text: "URL",
              sortable: true,
              options: {
                textMaxWidth: "200px",
              },
            },
            lockedTime: {
              text: "Ngày khóa",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastSyncTime: {
              text: "Ngày đồng bộ gần nhất",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tiktokShopLastAffiliateSyncTime: market.code.includes("ttshop") && {
              text: "Ngày đồng bộ Affiliate gần nhất",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tiktokLastExtensionSyncTime: market.code.includes("-ttshop") && {
              text: "Ngày đồng bộ Extension gần nhất",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            shopeeLastExtensionSyncTime: market.code.includes("-sh") && {
              text: "Ngày đồng bộ Extension gần nhất",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            warehouse: {
              text: "Kho",
              options: {
                subProp: "warehouse.name",
                sortBy: "warehouseId",
                label: true,
              },
            },
            users: {
              text: "User",
              options: {
                labels: true,
                labelItems(value) {
                  return value.name;
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            paymentInitBalance: {
              text: "Số dư khởi tạo",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            action: {},
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("shops", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "800px",
                  },
                },
              },
            },
            tiktokShopSyncAll: market.code.includes("ttshop") &&
              userManager.checkRole(["kt"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  text: "Đồng bộ TiktokShop",
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const dataTable = self.context();
                    self.loading.value = true;
                    await dataTable.execute(
                      async () => {
                        return await coreApiClient.call("integrationTiktokShop", "syncAll");
                      },
                      undefined,
                      {
                        disableLoading: true,
                        willRefresh: true,
                      }
                    );
                    self.loading.value = false;
                  },
                },
              },
            lazadaSyncAll: market.code.includes("lz") &&
              userManager.checkRole(["kt"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  text: "Đồng bộ Lazada",
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const dataTable = self.context();
                    self.loading.value = true;
                    await dataTable.execute(
                      async () => {
                        return await coreApiClient.call("integrationLazada", "syncAll");
                      },
                      undefined,
                      {
                        disableLoading: true,
                        willRefresh: true,
                      }
                    );
                    self.loading.value = false;
                  },
                },
              },
            shopeeSyncAll: market.code.includes("-sh") &&
              userManager.checkRole(["kt"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  text: "Đồng bộ Shopee",
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const dataTable = self.context();
                    self.loading.value = true;
                    await dataTable.execute(
                      async () => {
                        return await coreApiClient.call("integrationShopee", "syncAll");
                      },
                      undefined,
                      {
                        disableLoading: true,
                        willRefresh: true,
                      }
                    );
                    self.loading.value = false;
                  },
                },
              },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "800px",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              moveUser: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-account",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Chuyển user",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Chuyển user",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextUserId: item.nextUserId,
                            nextFromUserId: item.nextFromUserId,
                            nextUserStartTime: item.nextUserStartTime,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextUserId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "User",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("users", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    nextFromUserId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Chuyển từ user",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        "item-disabled": "_",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("users", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                      ext: {
                                        condition(form) {
                                          const dialog = form.context();
                                          const { item } = dialog.context().context();
                                          return item.userIds?.length >= 2;
                                        },
                                      },
                                    },
                                    nextUserStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển user",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "shops",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextUserId: data.nextUserId,
                                        nextFromUserId: data.nextFromUserId,
                                        nextUserStartTime: data.nextUserStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              updatePaymentAccount: userManager.checkRole(["kt"]) &&
                false && {
                  attrs: {},
                  content: {
                    icon: "mdi-credit-card-outline",
                  },
                  target: {
                    tooltip: {
                      content: {
                        text: "Cập nhật TKNH thanh toán",
                      },
                    },
                    dialog: {
                      handlers: {
                        initialize() {
                          this.options.content.buttons.save.states.enabled = new DataContainer(false);
                        },
                      },
                      content: {
                        autofocus: false,
                        toolbar: {
                          title: "Cập nhật TKNH thanh toán",
                          subTitle(dialog) {
                            const btn = dialog.context();
                            const { item } = btn.context();
                            return item.name;
                          },
                        },
                        content: {
                          type: "XForm",
                          makeAttrs(attrs, dialog) {
                            attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                            attrs.xData = new DataContainer({});
                            return attrs;
                          },
                          attrs: {
                            xOptions: {
                              content: {
                                sections: {
                                  default: {
                                    fields: {
                                      paymentAccountId: {
                                        type: "XAutocomplete",
                                        attrs: {
                                          label: "TKNH",
                                          required: true,
                                          "item-value": "_id",
                                          "item-text": "name",
                                          xOptions: {
                                            content: {
                                              async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("paymentAccounts", "findAll", {
                                                  payload: JSON.stringify({
                                                    limit,
                                                    search,
                                                    filters: [
                                                      {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                      },
                                                    ],
                                                  }),
                                                });
                                                items.forEach((item) => {
                                                  item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                                                });
                                                return items;
                                              },
                                            },
                                          },
                                        },
                                      },
                                      startTime: {
                                        type: "XDateTimePicker",
                                        attrs: {
                                          label: "Ngày bắt đầu",
                                          required: true,
                                        },
                                      },
                                      endTime: {
                                        type: "XDateTimePicker",
                                        attrs: {
                                          label: "Ngày kết thúc",
                                          required: true,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        buttons: {
                          save: {
                            content: {
                              text: "Cập nhật",
                            },
                            states: {},
                            on: {
                              async xClick({ self }) {
                                const dialog = self.context();
                                const btn = dialog.context();
                                const { item, dataTable } = btn.context();
                                const form = dialog.contentContainer.value;
                                const data = form.getData();
                                self.loading.value = true;
                                const result = await dataTable.execute(
                                  async () => {
                                    return await coreApiClient.call("shops", "updatePaymentAccount", {
                                      id: item._id,
                                      ...data,
                                    });
                                  },
                                  undefined,
                                  {
                                    disableLoading: true,
                                    willRefresh: true,
                                  }
                                );
                                self.loading.value = false;
                                if (result) {
                                  dialog.hide();
                                }
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              tiktokShopLogin: market.code.includes("ttshop") && {
                attrs: {},
                content: {
                  icon: "fab fa-tiktok",
                },
                on: {
                  xClick() {
                    const url = coreApiClient.makeDownloadUrl("integrationTiktokShop", "login", undefined, true);
                    location.href = url;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Đăng nhập Tiktokshop",
                    },
                  },
                },
                ext: {
                  condition(_, item) {
                    return !item.tiktokShopRefreshTokenExpiredTime || item.tiktokShopRefreshTokenExpiredTime < new Date().getTime();
                  },
                },
              },
              tiktokShopSync: {
                attrs: {},
                content: {
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const { item } = self.context();
                    self.loading.value = true;
                    await coreApiClient.call("integrationTiktokShop", "sync", {
                      shopId: item._id,
                    });
                    self.loading.value = false;
                  },
                },
                ext: {
                  condition(_, item) {
                    return item.tiktokShopRefreshTokenExpiredTime && item.tiktokShopRefreshTokenExpiredTime > new Date().getTime();
                  },
                },
              },
              lazadaLogin: market.code.includes("lz") && {
                attrs: {},
                content: {
                  icon: "mdi-shopping",
                },
                on: {
                  xClick() {
                    const url = coreApiClient.makeDownloadUrl("integrationLazada", "login", undefined, true);
                    location.href = url;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Đăng nhập Lazada",
                    },
                  },
                },
                ext: {
                  condition(_, item) {
                    return !item.lazadaRefreshTokenExpiredTime || item.lazadaRefreshTokenExpiredTime < new Date().getTime();
                  },
                },
              },
              lazadaSync: {
                attrs: {},
                content: {
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const { item } = self.context();
                    self.loading.value = true;
                    await coreApiClient.call("integrationLazada", "sync", {
                      shopId: item._id,
                    });
                    self.loading.value = false;
                  },
                },
                ext: {
                  condition(_, item) {
                    return item.lazadaRefreshTokenExpiredTime && item.lazadaRefreshTokenExpiredTime > new Date().getTime();
                  },
                },
              },
              shopeeLogin: market.code.includes("-sh") && {
                attrs: {},
                content: {
                  icon: "mdi-shopping",
                },
                on: {
                  xClick() {
                    const url = coreApiClient.makeDownloadUrl("integrationShopee", "login", undefined, true);
                    location.href = url;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Đăng nhập Shopee",
                    },
                  },
                },
                ext: {
                  condition(_, item) {
                    return !item.shopeeRefreshTokenExpiredTime || item.shopeeRefreshTokenExpiredTime < new Date().getTime();
                  },
                },
              },
              shopeeAdsLogin: market.code.includes("-sh") && {
                attrs: {},
                content: {
                  icon: "mdi-advertisements",
                },
                on: {
                  xClick() {
                    const url = coreApiClient.makeDownloadUrl("integrationShopee", "adsLogin", undefined, true);
                    location.href = url;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Đăng nhập Shopee Ads",
                    },
                  },
                },
                ext: {
                  condition(_, item) {
                    return !item.shopeeAdsRefreshTokenExpiredTime || item.shopeeAdsRefreshTokenExpiredTime < new Date().getTime();
                  },
                },
              },
              shopeeSync: {
                attrs: {},
                content: {
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const { item } = self.context();
                    self.loading.value = true;
                    await coreApiClient.call("integrationShopee", "sync", {
                      shopId: item._id,
                    });
                    self.loading.value = false;
                  },
                },
                ext: {
                  condition(_, item) {
                    return item.shopeeRefreshTokenExpiredTime && item.shopeeRefreshTokenExpiredTime > new Date().getTime();
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("shops", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              return await coreApiClient.call("shops", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "shops",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("shops", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              url: {
                attrs: {
                  label: "URL",
                  required: true,
                },
              },
              shopUsername: {
                attrs: {
                  label: "Shop Username",
                  required: true,
                },
              },
              shopName: {
                attrs: {
                  label: "Shop Name",
                },
              },
              shopId: {
                attrs: {
                  label: "Shop ID",
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(shopStatuses),
                },
                ext: {
                  defaultValue: "active",
                },
              },
              email: {
                attrs: {
                  label: "Email",
                },
              },
              lockedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày khóa",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              userIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              paymentInitBalance: {
                type: "number",
                attrs: {
                  label: "Số dư khởi tạo",
                },
              },
              paymentAccountItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Lịch sử tài khoản thanh toán",
                  xOptions: {
                    content: {
                      itemProperties: {
                        paymentAccountId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "TKNH",
                            required: true,
                            "item-value": "_id",
                            "item-text": "name",
                            xOptions: {
                              content: {
                                async itemsLoader() {
                                  const { items } = await coreApiClient.call("paymentAccounts", "findAll", {
                                    limit: -1,
                                  });
                                  items.forEach((item) => {
                                    item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        startTime: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày bắt đầu",
                            required: true,
                          },
                        },
                        endTime: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày kết thúc",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["name", "shopUsername", "shopName", "shopId", "status", "email", "description", "url", "lockedTime", "warehouseId", "userIds", "paymentInitBalance", "paymentAccountItems"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["name", "shopUsername", "shopName", "shopId", "status", "email", "description", "url", "lockedTime", "warehouseId", "userIds", "paymentInitBalance", "paymentAccountItems"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
